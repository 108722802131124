// Import Modules
@import 'mixins';
@import 'libraries';
@import 'modules';
@import 'components';
@import 'pages';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
    font-family: $mainFont;
    font-weight: 300;
    overflow-x: hidden;
    width: 100vw;
    font-size: $sm;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
}

a {
    color:$link;
    transition: all $duration $easingLight;

    &:hover{
        color:$linkHover;
        text-decoration: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#fp-nav {
  left: 15px !important;

  @include mq(md) {
    left: 30px !important;
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      margin: 10px 0 !important;

      @include mq(md) {
        margin: 20px 0 !important;
      }

      a {
          span {
          height: 7px !important;
          width: 7px !important;
          background: $white - #333 !important;
          transform: translate3d(-50%, -50%, 0);
          margin: 0 !important;

          @include mq(md) {
            height: 10px !important;
            width: 10px !important;
            background: $white - #333 !important;
            transform: translate3d(-50%, -50%, 0);
            margin: 0 !important;
          }
        }
      }
    }
  }

  ul {
    li {
      a {
        &.active {
          span {
            background: $blue !important;
            height: 16px !important;
            width: 16px !important;
          }
        }
      }
    }
  }
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
