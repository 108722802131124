.arrows {
  $a: &;
  
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: calc(100vw - 100px);

  @include mq(md) {
    flex-direction: column;
    width: auto;
  }

  &__right,
  &__left {
    width: 60px;
    height: 60px;
    border: 1px solid $white;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    @include mq(md) {
      width: 100px;
      height: 100px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 52%;
      transform: translate3d(-50%, -50%, 0);
      width: 15%;
    }

    &.--animate {
      animation: bounce 2s infinite; 
    }
  }

  &__left {
    img {
      left: 48%;
    }
  }

  &.both {
    justify-content: space-between;

    #{$a}__right,
    #{$a}__left {
      width: 40px;
      height: 40px;
      border: 1px solid $white;
      border-radius: 50%;
      position: relative;
      
      @include mq(md) {
        margin: 10px 0;
        width: 60px;
        height: 60px;
      }

      img {
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate3d(-50%, -50%, 0);
        width: 25%;
      }
    }

    #{$a}__right {
      img {
        left: 53%;
      }
    }
  }

  // &__right {
  //   width: 100px;
  //   height: 100px;
  //   border: 1px solid $white;
  //   border-radius: 50%;
  //   position: relative;

  //   img {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate3d(-50%, -50%, 0);
  //     width: 15%;
  //   }
  // }

  // &__left {
  //   width: 100px;
  //   height: 100px;
  //   border: 1px solid $white;
  //   border-radius: 50%;
  //   position: relative;

  //   img {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate3d(-50%, -50%, 0);
  //     width: 15%;
  //   }
  // }
}

@keyframes bounce {
  20%, 53%, 80%, 0%, 100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-30px, 0, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-15px, 0, 0);
  }
  90% {
    transform: translate3d(-4px, 0, 0);
  }
}