.home {
  scroll-behavior: smooth;
  background: $darkestGray;

  .section {
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: all .5s linear;
    z-index: 2;
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: $black;
      opacity: .3;
      z-index: 1;
    }

    &:nth-child(1) {
      background: url('/images/slide-1.png') no-repeat center center / cover;
    }

    &:nth-child(2) {
      background: url('/images/slide-2.png') no-repeat center center / cover;
    }

    &:nth-child(3) {
    }

    &:nth-child(4) {
      background: url('/images/slide-4.png') no-repeat center center / cover;
    }

    &:nth-child(5) {
      background: url('/images/slide-5.png') no-repeat center center / cover;
    }
  }

  &__section-1,
  &__section-2,
  &__section-3,
  &__section-4,
  &__section-5 {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
  }

  &__section-1 {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(50%);
      height: 15%;
      width: 1px;
      background: $white;
    }

    &__logo {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 80%;
      pointer-events: none;

      @include mq(md) {
        top: 50%;
        width: auto;
      }

      img {
        width: 100%;

        @include mq(md) {
          width: auto;
        }
      }
    }
  }

  &__section-2 {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(50%);
      height: 15%;
      width: 1px;
      background: $white;
    }

    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: calc(100% - 80px);
      font-size: 24px;
      text-align: center;
      color: $white;
      font-weight: 300;

      @include mq(md) {
        width: 80%;
        font-size: 42px;
      }
    }
  }

  &__section-3 {
    &__slide {
      height: 100vh;
      width: 100vw;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .5s linear;

      &.next-slide {
        left: 100%;
      }
      &.prev-slide {
        left: -100%;
      }
    }

    &__slide {
      &--first {
        background: url('/images/slide-3.png') no-repeat center center / cover;
      }

      &--second {
        background: url('/images/slide-6.png') no-repeat center center / cover;
      }

      &--third {
        background: url('/images/slide-7.png') no-repeat center center / cover;
      }

      &--fourth {
        background: url('/images/slide-8.png') no-repeat center center / cover;
      }

      &--fifth {
        background: url('/images/slide-9.png') no-repeat center center / cover;
      }
    }

    &__title {
      font-size: 32px;
      color: $white;
      text-transform: uppercase;
      font-weight: 300;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      margin: 0;

      @include mq(md) {
        font-size: 68px;
        top: 50%;
      }
    }

    &__arrows-container {
      // left: 50%;
      // bottom: 0;
      // transform: translate3d(-50%, 0, 0);
      
      @include mq(md) {
        right: 0;
        top: 50%;
        left: auto;
        bottom: auto;
        transform: translate3d(0, -50%, 0);
        position: absolute;
      }

      &--single {
        right: 15px;
        bottom: auto;
        top: 45%;
        transform: translate3d(0, -50%, 0);
        position: absolute;

        .arrows {
          width: auto;
        }
  
        @include mq(md) {
          top: 50%;
          right: 30px;
        }
      }
    }

    &__container {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @include mq(md) {
        top: 50%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 80%;
        height: auto;
        left: auto;
        right: 50px;
        transform: translate3d(0, -50%, 0);
      }

      &--align-items-end {
        align-items: flex-end;
      }
    }

    &__wrapper {
      width: 100%;

      @include mq(md) {
        width: 400px;
        margin-right: 300px;
      }

      &--big {
        @include mq(md) {
          width: 600px;
          margin-right: 0;
        }
      }
    }

    &__wrapper-title {
      color: $white;
      font-size: 26px;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 40px;
      font-weight: 300;

      @include mq(md) {
        font-size: 62px;
      }

      span {
        font-weight: 500;
      }
    }

    &__wrapper-text {
      color: $white;
      font-size: 16px;
      margin: 0;
      margin-bottom: 40px;

      @include mq(md) {
        font-size: 24px;
        margin-bottom: 0;
      }

      &--small {
        @include mq(md) {
          width: 75%;
        }
      }
    }

    &__list {
      // width: 300px;
      @include mq(md) {
        padding-right: 100px;
      }
    }

    &__item {
      font-size: 16px;
      margin-bottom: 20px;
      color: $white;

      @include mq(md) {
        font-size: 20px;
      }

      &:nth-child(1) {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 110%;
          width: 200px;
          height: 1px;
          background: $white;
          display: none;

          @include mq(md) {
            display: block;
          }
        }
      }
    }

    &__logo-wrapper {
      width: 100%;

      @include mq(md) {
        border-left: 1px solid $white;
        width: 400px;
        padding-left: 40px;
      }

      img {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }

      a {
        display: block;
        text-decoration: none;
        color: $white;
        font-size: 22px;
        text-align: center;

        @include mq(md) {
          font-size: 26px;
          text-align: left;
        }
      }
    }
  }

  &__section-4 {
    &__container {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 80%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 10;

      @include mq(md) {
        top: 50%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        height: 70%;
      }
    }

    &__container-logo {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include mq(md) {
        width: 50%;
        position: absolute;
        top: 0;
        left: -100px;
      }

      img {
        width: 100%;

        @include mq(md) {
          width: auto;
        }
      }
    }

    &__group {
      display: flex;
      flex-direction: column-reverse;

      @include mq(md) {
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &__text-group {
      position: relative;
      // height: 15%;
      width: 100%;

      @include mq(md) {
        height: 50vh;
        width: 40vw;
      }
    }

    &__text {
      @include trans;

      color: $white;
      font-size: 26px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      text-align: center;
      width: 180%;
      margin: 0;

      @include mq(md) {
        width: auto;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        margin: 0;
        font-size: 62px;
        text-align: right;
        transform: translate3d(0, 0, 0);
      }

      &.-active {
        opacity: 1;
      }
    }

    &__group-list {
      margin-bottom: 40px;

      @include mq(md) {
        padding-left: 50px;
        margin-left: 50px;
        border-left: 1px solid $white;
        margin-bottom: 0;
      }
    }

    &__group-item {
      @include trans;
      color: $white;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      @include mq(md) {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }

      span {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;

        @include mq(md) {
          font-size: 32px;
  
        }
      }


      &.-active {
        color: $blue;
      }
    }
  }

  &__section-5 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    
    @include mq(md) {
      align-items: flex-end;
      padding-bottom: 100px;
    }

    &__container {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      z-index: 10;

      @include mq(md) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 80%;
      }
    }

    &__wrapper {
      width: 100%;

      @include mq(md) {
        width: 33.33%;
      }
    }

    &__logo {
      width: 100%;
      margin-bottom: 40px;

      @include mq(md) {
        margin-bottom: 0;
      }

      img {
        display: block;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__group {
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      width: auto;
      margin-bottom: 40px;
      
      @include mq(md) {
        border-left: 1px solid $white;
        margin-bottom: 0;
      }
    }

    &__group-title {
      font-size: 24px;
      color: $blue;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
    }

    &__group-text,
    &__group-phone,
    &__group-mail {
      display: block;
      color: $white;
      font-size: 18px;
      margin: 0;
      margin-bottom: 10px;
      text-decoration: none;
    }
  }
}


@keyframes bounce {
  20%, 53%, 80%, 0%, 100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-30px, 0, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(-15px, 0, 0);
  }
  90% {
    transform: translate3d(-4px, 0, 0);
  }
}